import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static targets = ["scrollArea"]
  static values = {
    scaffoldUrl: String,
    currentPage: Number,
    totalPages: Number,
    loadingState: Boolean,
  }

  scaffoldUrlValue: string
  currentPageValue: number
  totalPagesValue: number
  loadingStateValue: Boolean

  connect() {
    this.createObserver()
    this.loadingStateValue = false
  }

  scrollAreaTarget: HTMLSelectElement

  createObserver() {
    const observer = new IntersectionObserver((entries) => this.handleIntersect(entries), {
      // https://github.com/w3c/IntersectionObserver/issues/124#issuecomment-476026505
      threshold: [0, 1.0],
    })
    observer.observe(this.scrollAreaTarget)
  }

  handleIntersect(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this.loadMore()
      }
    })
  }

  loadMore() {
    // Do not initiate a request if one is in progress
    // Do not initiate a request if we're on the last page
    if (this.loadingStateValue == true || this.currentPageValue == this.totalPagesValue) {
      return
    }
    this.loadingStateValue = true

    let nextPage = this.currentPageValue + 1
    let nextURL = new URL(this.scaffoldUrlValue)
    nextURL.searchParams.set("page", nextPage.toString())

    if (!nextURL) {
      return
    }

    fetch(nextURL, {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    })
      .then((r) => r.text())
      .then((html) => Turbo.renderStreamMessage(html))
      // NOTE because we're scrolling up, we need to scroll down to not always see trigger
      .then((_) =>
        document
          .getElementById(`activities_page_${this.currentPageValue}`)
          ?.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" }),
      )
      .then((_) => (this.currentPageValue = nextPage))
      .then((_) => (this.loadingStateValue = false))
  }
}
