import { Controller } from "@hotwired/stimulus"
import { hide, show, enable, disable } from "../utils"
import { get } from "@rails/request.js"
import * as Sentry from "@sentry/browser"

// Connects to data-controller="coupa-region-widget"
export default class extends Controller {
  static targets = ["regionSelect", "regionSelectContainer", "loader", "stateIsoCode"]
  static values = { getOptionsUrl: String }

  regionSelectContainerTarget: HTMLElement
  loaderTarget: HTMLElement
  regionSelectTarget: HTMLInputElement
  stateIsoCodeTarget: HTMLInputElement
  getOptionsUrlValue: string
  currentRegionData: any

  handleCountryChange(event) {
    const countryCode = event.target.value

    if (countryCode) {
      show(this.regionSelectContainerTarget)
      enable(this.regionSelectTarget)
      this.fetchRegions(countryCode)
    } else {
      hide(this.regionSelectContainerTarget)
      disable(this.regionSelectTarget)
    }
  }

  handleRegionChange(event) {
    const region = event.target.value
    const regionData = this.currentRegionData.find((data) => data.value === region)
    if (regionData) {
      this.stateIsoCodeTarget.value = regionData["state_iso_code"]
    } else {
      this.stateIsoCodeTarget.value = ""
    }
  }

  getRegionsPath(countryCode) {
    return `/api/countries/${countryCode}/state_regions`
  }

  async fetchRegions(countryCode) {
    const regionTomSelect = this.regionSelectTarget.tomselect
    this.showLoader()
    this.clearRegionSelect(regionTomSelect)

    try {
      const data = await this.getRegionData(countryCode)
      this.handleRegionData(data, regionTomSelect)
    } catch (error) {
      this.handleError(error)
    } finally {
      this.hideLoader()
    }
  }

  clearRegionSelect(regionTomSelect) {
    regionTomSelect.clear()
    regionTomSelect.clearOptions()
    this.stateIsoCodeTarget.value = ""
  }

  async getRegionData(countryCode) {
    const params = new URLSearchParams({
      resource_type: "state_regions",
      path_id: countryCode,
      "additional_fields[]": "state_iso_code",
    }).toString()

    const res = await get(`${this.getOptionsUrlValue}?${params}`, {
      contentType: "application/json",
      responseKind: "json",
    })
    return await res.json
  }

  handleRegionData(data, regionTomSelect) {
    this.currentRegionData = data

    if (data.length === 0) {
      hide(this.regionSelectContainerTarget)
      disable(this.regionSelectTarget)
    } else {
      show(this.regionSelectContainerTarget)
      enable(this.regionSelectTarget)
      regionTomSelect.addOptions(data)
    }
  }

  handleError(error) {
    console.error("Unable to fetch regions:", error)
    Sentry.captureException(error)
  }

  showLoader() {
    show(this.loaderTarget)
  }

  hideLoader() {
    hide(this.loaderTarget)
  }
}
