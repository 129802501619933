import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="price-checks-v2--line-item--price-driver-search"
export default class extends Controller {
  static targets = ["contractPriceDriverSelect"]
  contractPriceDriverSelectTarget: HTMLSelectElement

  static values = {
    bucketOptions: String,
    submitButton: String,
    tableBody: String,
  }
  bucketOptionsValue: string
  submitButtonValue: string
  tableBodyValue: string

  addPriceDriverFilter() {
    this.addPriceDriverFilterRow()
    const tsControl = this.contractPriceDriverSelectTarget.id + "-ts-control"
    document.getElementById(tsControl).blur()
    this.submitForm()
  }

  addPriceDriverFilterRow() {
    const values = this.getValues()
    const filterName = this.contractPriceDriverSelectTarget.value
    const tableBody: HTMLElement = document.getElementById(this.tableBodyValue)
    const tableRow: HTMLTableRowElement = document.createElement("tr")

    tableRow.innerHTML =
      `<input type="hidden" name="price_drivers_filters[${filterName}][name]" id="price_drivers_filters_${filterName}_name" value="${filterName}">\n` +
      `<input type="hidden" name="price_drivers_filters[${filterName}][filterable]" id="price_drivers_filters_${filterName}_filterable" value="${JSON.stringify(values.filterable)}">\n` +
      `<input type="hidden" name="price_drivers_filters[${filterName}][buckets]" id="price_drivers_filters_${filterName}_buckets" value="${JSON.stringify(values.buckets)}">\n` +
      `<input type="hidden" name="price_drivers_filters[${filterName}][contract_count]" id="price_drivers_filters_${filterName}_contract_count" value="${JSON.stringify(values.contract_count)}">\n` +
      `<input type="hidden" name="price_drivers_filters[${filterName}][selected_bucket]" id="price_drivers_filters_${filterName}_contract_count" value="all">\n`

    tableBody.prepend(tableRow)
  }

  getValues() {
    const options = JSON.parse((document.getElementById(this.bucketOptionsValue) as HTMLInputElement).value)
    return options[
      options.findIndex((priceDriverOption) => priceDriverOption.name === this.contractPriceDriverSelectTarget.value)
    ]
  }

  submitForm() {
    const button = document.getElementById(this.submitButtonValue) as HTMLButtonElement
    button.form.requestSubmit()
  }
}
