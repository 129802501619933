import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="supplier--timeframe-filter"
export default class extends Controller {
  static targets = ["customStartDateSelect", "customEndDateSelect", "timeframeFilterScope"]
  static values = {
    periodsOptionsData: String,
  }

  customStartDateSelectTarget: HTMLSelectElement
  customEndDateSelectTarget: HTMLSelectElement
  timeframeFilterScopeTarget: HTMLInputElement

  onTabChangeFunction: (event) => void

  connect() {
    this.onTabChangeFunction = this.onTabChange.bind(this)
    window.addEventListener("TabsComponent:tabChanged", this.onTabChangeFunction)
  }

  disconnect() {
    window.removeEventListener("TabsComponent:tabChanged", this.onTabChangeFunction)
  }

  clearFilters(): void {
    const form = this.timeframeFilterScopeTarget.form

    if (form) {
      this.timeframeFilterScopeTarget.value = ""
      this.clearSelects()
      form.requestSubmit()
    }
  }

  private onTabChange(event): void {
    const value = event.detail.value

    this.timeframeFilterScopeTarget.value = value
  }

  private clearSelects(): void {
    const selects = [this.customStartDateSelectTarget, this.customEndDateSelectTarget]

    selects.forEach((select) => {
      select.tomselect && select.tomselect.clear()
      select.value = ""
    })
  }
}
