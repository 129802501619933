import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="request-form--radio-button-field"
export default class extends Controller {
  static targets = ["toggleLink", "optionContainer"]
  optionContainer: HTMLElement[]

  toggleLinkTargetConnected(target) {
    const isHidden = target.getAttribute("data-is-hidden")
    const optionContainer = this.findAncestorOptionContainer(target)
    if (optionContainer) {
      if (isHidden == "true") {
        optionContainer.classList.add("disabled")
      } else {
        optionContainer.classList.remove("disabled")
      }
    }
  }

  findAncestorOptionContainer(target: HTMLElement): HTMLElement | null {
    let currentElement = target

    while (currentElement) {
      const foundElement = currentElement.querySelector(
        `[data-request-form--radio-button-field-target="optionContainer"]`,
      )
      if (foundElement) {
        return foundElement as HTMLElement
      }

      currentElement = currentElement.parentElement
    }

    return null
  }

  disconnect() {}
}
