import { Controller } from "@hotwired/stimulus"
import { hide, show } from "../utils"

// Connects to data-controller="stage-select-component"
export default class extends Controller {
  static targets = [
    "renewingContractNotOnTropic",
    "renewingContractSelection",
    "renewingContractSelectionWrapper",
    "stageSelection",
    "terminationDateFieldWrapper",
    "terminationDateField",
    "alertDiv",
    "warningDiv",
  ]

  static values = {
    originalStage: String,
  }

  static outlets = ["styled-select"]

  renewingContractNotOnTropicTarget: HTMLSelectElement
  renewingContractSelectionTarget: HTMLSelectElement
  renewingContractSelectionWrapperTarget: HTMLDivElement
  stageSelectionTarget: HTMLSelectElement
  terminationDateFieldTarget: HTMLInputElement
  terminationDateFieldWrapperTarget: HTMLDivElement
  hasSendRenewalReminderFieldTarget: boolean
  alertDivTarget: HTMLDivElement
  warningDivTarget: HTMLDivElement

  originalStageValue: string

  connect() {
    this.stageSelected()
    if (this.hasSendRenewalReminderFieldTarget) {
      this.renewingNotOnTropicSelected()
      this.renewingContractSelected()
    }
  }

  renewingContractSelected() {
    const selectedContract = this.renewingContractSelectionTarget.selectedOptions[0].value

    if (selectedContract) {
      this.renewingContractNotOnTropicTarget.checked = false
      this.renewingContractNotOnTropicTarget.disabled = true
    } else {
      this.renewingContractNotOnTropicTarget.disabled = false
    }
  }

  renewingNotOnTropicSelected() {
    if (this.renewingContractNotOnTropicTarget.checked) {
      this.styledSelectOutlet.disableInput()
      this.styledSelectOutlet.addPlaceholder("Renewed Contract is not in Tropic")
    } else {
      this.styledSelectOutlet.enableInput()
      this.styledSelectOutlet.addPlaceholder("Select Contract")
    }
  }

  stageSelected() {
    const renewingContractSelectWrapper = this.renewingContractSelectionWrapperTarget

    const selectedStage = this.stageSelectionTarget.selectedOptions[0].value

    if (selectedStage === "terminated") {
      this.renewingContractSelectionWrapperTarget.classList.add("hidden")
      this.showTerminateDateField()
    } else {
      this.renewingContractSelectionWrapperTarget.classList.remove("hidden")
      this.hideTerminateDateField()
    }

    const originalIsSelected = (this.originalStageValue || "disconnect") === selectedStage

    if (this.originalStageValue === "renewal_in_progress") {
      if (originalIsSelected) {
        hide(this.alertDivTarget)
        show(this.warningDivTarget)
      } else {
        show(this.alertDivTarget)
        hide(this.warningDivTarget)
      }
    }

    const stageChangedToRenewed = selectedStage === "renewed" && !originalIsSelected
    if (stageChangedToRenewed) {
      show(renewingContractSelectWrapper)
    } else if (selectedStage !== "renewed") {
      hide(renewingContractSelectWrapper)
      renewingContractSelectWrapper.querySelector("select").value = ""
    }
  }

  private showTerminateDateField() {
    show(this.terminationDateFieldWrapperTarget)
    this.terminationDateFieldTarget.required = true
  }

  private hideTerminateDateField() {
    hide(this.terminationDateFieldWrapperTarget)
    this.terminationDateFieldTarget.required = false
  }
}
