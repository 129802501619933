import { Controller } from "@hotwired/stimulus"
import { enable, show, isEmpty } from "../utils"

// Connects to data-controller="acknowledge-ai-indication"
export default class extends Controller {
  static targets = ["aiIndication", "confirmation", "selectWrapper", "select"]
  static values = {
    predictionId: String,
  }

  predictionIdValue: string
  aiIndicationTarget?: HTMLElement
  hasAiIndicationTarget: boolean
  confirmationTarget?: HTMLElement
  selectTarget?: HTMLSelectElement
  hasSelectWrapperTarget: boolean
  selectWrapperTarget?: HTMLSelectElement

  connect() {}

  onSelectChange() {
    if (this.hasAiIndicationTarget) {
      this.aiIndicationTarget.remove()
    }
  }

  onConfirmSuggestion() {
    this.selectTarget.value = this.predictionIdValue
    this.selectTarget.classList.remove("is-empty")

    this.removeConfirmationElements()
    this.updateWrapperAndStyling()
  }

  onRejectSuggestion() {
    if (this.selectTarget.tomselect) {
      this.selectTarget.tomselect.clear()
    } else {
      this.selectTarget.value = ""
    }
    this.selectTarget.classList.add("is-empty")

    this.removeConfirmationElements()
    this.updateWrapperAndStyling()
  }

  removeConfirmationElements() {
    this.confirmationTarget.remove()
    if (this.hasAiIndicationTarget) {
      this.aiIndicationTarget.remove()
    }
    const lowAiConfidenceConfirmation = document.querySelector(
      'div[data-acknowledge-ai-indication-target="confirmation"]',
    )
    if (!lowAiConfidenceConfirmation) {
      window.dispatchEvent(
        new CustomEvent("FullScreen:submitButtonStatusChanged", {
          detail: { enable: true },
        }),
      )
    }
  }

  updateWrapperAndStyling() {
    if (this.hasSelectWrapperTarget) {
      enable(this.selectTarget)
      show(this.selectWrapperTarget)
    } else {
      let containerId = this.selectTarget.name.match(/\[(.*?)\]/g)[1]
      containerId = containerId.replace("_", "-").replace(/[\[\]']+/g, "")
      let container = document.getElementById(`document-${containerId}`)

      if (this.selectTarget.tomselect) {
        this.selectTarget.tomselect.enable()
        container.getElementsByClassName("ts-wrapper")[0].setAttribute("style", "background-color: white;")
        const tsControl = container.getElementsByClassName("ts-control")[0]
        tsControl.setAttribute("style", "border-radius: 6px")
      } else {
        enable(this.selectTarget)
      }

      if (!isEmpty(container)) {
        if (containerId === "renewal-type") {
          container.classList.remove("w-3/4")
          container.classList.add("w-full")
        } else {
          container.classList.remove("w-full")
          container.classList.remove("pr-3")
          container.classList.add("w-3/4")
        }
      }
    }
  }
}
