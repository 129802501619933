import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="templates-wrapper"
export default class extends Controller {
  static outlets = ["templates"]
  static targets = ["container"]
  static values = {
    max: Number,
    cloneWrapperSelector: {
      type: String,
      default: ".clone-wrapper",
    },
  }

  containerTarget: HTMLDivElement
  maxValue: number
  cloneWrapperSelectorValue: string

  checkClonesCount() {
    const maxMet = this.renderedCloneCount() >= this.maxValue

    this.templatesOutlets.forEach((outlet) => {
      outlet.handleCountCheckResult(maxMet)
    })
  }

  renderedCloneCount() {
    const clones = Array.from(this.containerTarget.querySelectorAll(this.cloneWrapperSelectorValue)).filter(
      (el) => !el.closest("template"),
    )
    return clones.length
  }
}
