import { Controller } from "@hotwired/stimulus"
import { isEmpty, setSessionStorageValue, getSessionStorageValue } from "../utils"

// Connects to data-controller="bulk-actions"
export default class extends Controller {
  static targets = ["form", "bulkActionBtn"]

  static values = {
    selectionHeaderId: String,
    preselectFromUrl: { type: Boolean, default: false },
    isSingleSelect: { type: Boolean, default: false },
    highlightAvatar: { type: Boolean, default: false },
    rowColor: { type: String, default: "bg-purple-100" },
    recordIdParam: { type: String, default: "record_id" },
    recordIdsSetYetParam: { type: String, default: "is_bulk_records_set" },
    disableFilterWhenSelected: { type: Boolean, default: false },
    maxSelectionCount: { type: Number },
  }

  formTarget: HTMLFormElement
  bulkActionBtnTarget: HTMLLinkElement

  hasBulkActionBtnTarget: boolean
  preselectFromUrlValue: boolean
  isSingleSelectValue: boolean
  rowColorValue: string
  highlightAvatarValue: boolean
  formChangeUrlValue: string
  recordIdParamValue: string
  recordIdsSetYetParamValue: string
  disableFilterWhenSelectedValue: boolean
  maxSelectionCountValue: number

  checkToggleStatusFunc: (e) => void
  onClearSelectionFunction: (event) => void

  connect() {
    this.checkToggleStatusFunc = this.checkToggleStatus.bind(this)
    this.allToggles.forEach((toggle) => toggle.addEventListener("change", this.checkToggleStatusFunc))

    if (isEmpty(getSessionStorageValue(this.recordIdsSetYetParamValue))) {
      setSessionStorageValue(this.recordIdsSetYetParamValue, "true")
      if (this.preselectFromUrlValue) {
        let queryParams = new URLSearchParams(new URL(window.location.href).search)
        setSessionStorageValue(this.recordIdParamValue, Array.from(queryParams.getAll("record_id[]")))
      } else {
        setSessionStorageValue(this.recordIdParamValue, [])
      }
    }

    if (this.preselectFromUrlValue && this.togglesInThisTable().length > 0) {
      this.preselectTogglesAndShowHeader()
    }

    this.checkStatus()
    this.updateRows()
    if (
      this.maxSelectionCountValue > 0 &&
      getSessionStorageValue(this.recordIdParamValue).length === this.maxSelectionCountValue
    ) {
      this.disableOrEnableUncheckedCheckboxes("disable")
    }

    this.onClearSelectionFunction = this.exitBulkActionMode.bind(this)
    window.addEventListener("BulkActions:clear-selection", this.onClearSelectionFunction)
  }

  disconnect() {
    this.allToggles.forEach((toggle) => toggle.removeEventListener("change", this.checkToggleStatusFunc))
    window.removeEventListener("BulkActions:clear-selection", this.onClearSelectionFunction)
  }

  preselectTogglesAndShowHeader(): void {
    this.togglesInThisTable().forEach((toggle: HTMLInputElement) => {
      if (getSessionStorageValue(this.recordIdParamValue).includes(this.getRecordId(toggle))) {
        toggle.checked = true
        this.updateRows()
      }
    })

    this.showOrHideHeader(this.totalSelectionFromSession())
  }

  totalSelectionFromSession(): number {
    return getSessionStorageValue(this.recordIdParamValue).length
  }

  toggleAllRecordsInGroup(e): void {
    const { allUnchecked, someChecked } = this.checkedToggleStatusInGroup(e.target.id)
    const shouldCheckToggle = allUnchecked || someChecked
    e.target.checked = shouldCheckToggle
    this.togglesInThisGroup(e.target.id).forEach((toggle) => {
      toggle.checked = shouldCheckToggle
    })
    if (shouldCheckToggle) {
      e.target.indeterminate = false
    }
    this.checkToggleStatus(e)
    this.selectRecord(e)
  }

  toggleAllRecords(e): void {
    const { allUnchecked, someChecked } = this.checkedToggleStatus()

    const shouldCheckToggle = allUnchecked || someChecked

    const togglesToUpdate =
      this.maxSelectionCountValue > 0 && shouldCheckToggle
        ? this.allUncheckedUndisabledToggles.slice(0, this.maxSelectionCountValue - this.totalSelectionFromSession())
        : this.togglesInThisTable()
    togglesToUpdate.forEach((toggle) => {
      toggle.checked = shouldCheckToggle
    })
    this.allGroupToggles().forEach((toggle) => {
      toggle.indeterminate = false
      toggle.checked = shouldCheckToggle
    })

    const toggleParam = shouldCheckToggle ? "all" : "none"
    this.selectRecord(e, toggleParam)
  }

  checkToggleStatus(e): void {
    if (!this.element.contains(e.srcElement)) {
      // if the event target is not in the controller element, i.e. when a page has multiple tables that allow bulk-select,
      // such as the platform > contracts > contracts missing information, we're supposed to unselect selections when a new table is bulk-selected
      this.uncheckAllToggles()
    } else {
      // set select-all toggle state if one is present
      this.checkStatus()
      if (e.params.group) {
        this.checkStatusInGroup(e.params.group)
      }
    }
  }

  checkStatus() {
    const selectAllToggle = this.selectAllToggleInThisTable()
    if (selectAllToggle) {
      const { allChecked, someChecked } = this.checkedToggleStatus()

      if (someChecked) {
        selectAllToggle.indeterminate = true
      } else {
        selectAllToggle.indeterminate = false
        selectAllToggle.checked = allChecked
      }
    }
  }

  checkStatusInGroup(group) {
    const selectAllToggle = this.selectAllToggleInThisGroup(group)
    if (selectAllToggle) {
      const { allChecked, someChecked } = this.checkedToggleStatusInGroup(group)

      if (someChecked) {
        selectAllToggle.indeterminate = true
      } else {
        selectAllToggle.indeterminate = false
        selectAllToggle.checked = allChecked
      }
    }
  }

  checkedToggleStatus() {
    const toggles = this.togglesInThisTable()
    const checkedCount = this.allCheckedTogglesInThisTable().length
    const allChecked = toggles.length !== 0 && checkedCount === toggles.length
    const allUnchecked = checkedCount === 0
    const someChecked = !allChecked && !allUnchecked

    return {
      allChecked,
      allUnchecked,
      someChecked,
    }
  }

  checkedToggleStatusInGroup(group) {
    const toggles = this.togglesInThisGroup(group)
    const checkedCount = this.allCheckedTogglesInThisGroup(group).length
    const allChecked = toggles.length !== 0 && checkedCount === toggles.length
    const allUnchecked = checkedCount === 0
    const someChecked = !allChecked && !allUnchecked

    return {
      allChecked,
      allUnchecked,
      someChecked,
    }
  }

  exitBulkActionMode(): void {
    this.uncheckAllToggles()
    setSessionStorageValue(this.recordIdParamValue, [])
  }

  uncheckAllToggles(): void {
    const selectAllToggle = this.selectAllToggleInThisTable()
    if (selectAllToggle == null) {
      return
    }

    selectAllToggle.indeterminate = false
    selectAllToggle.checked = false

    const bulkSelectionHeader = this.selectionHeaderOverride() || this.selectionHeaderInThisTable()
    const tableHeader = document.querySelector("#table-header")
    const scopeBar = document.querySelector("#table-scope-bar")
    if (bulkSelectionHeader != null) {
      bulkSelectionHeader.classList.add("hidden")
      tableHeader?.classList.remove("hidden")
      scopeBar?.classList.remove("hidden")
    }

    this.togglesInThisTable().forEach((toggle: HTMLInputElement) => {
      toggle.checked = false
      if (!isEmpty(getSessionStorageValue(this.recordIdsSetYetParamValue))) {
        let newIds = getSessionStorageValue(this.recordIdParamValue).filter((id) => id != this.getRecordId(toggle))
        setSessionStorageValue(this.recordIdParamValue, newIds)
      }
      this.removeRowColor(toggle)
    })
    this.allGroupToggles().forEach((toggle) => {
      toggle.checked = false
      toggle.indeterminate = false
    })
  }

  selectRecord(e, toggle = ""): void {
    this.checkToggleStatus(e)
    this.updateRows()
    const recordId = this.getRecordId(e.target)
    let oldSelectedIds = []

    if (!isEmpty(getSessionStorageValue(this.recordIdsSetYetParamValue))) {
      let selectedIds = getSessionStorageValue(this.recordIdParamValue)
      oldSelectedIds = selectedIds

      if (recordId == "all" && toggle == "all") {
        // all toggled
        setSessionStorageValue(
          this.recordIdParamValue,
          [...selectedIds, ...this.selectedRecordIds].filter((id, i, self) => self.indexOf(id) === i),
        )
      } else if (recordId == "all" && toggle == "none") {
        // all untoggled
        const newSelectedIds = selectedIds.filter((id) => !this.allRecordIds.includes(id))
        setSessionStorageValue(this.recordIdParamValue, newSelectedIds)
      } else if (recordId) {
        // single toggled or untoggled
        if (e.target.checked) {
          selectedIds.push(recordId)
        } else {
          selectedIds = selectedIds.filter((id) => id != recordId)
        }
        setSessionStorageValue(this.recordIdParamValue, selectedIds)
      } else {
        // group toggled
        let newIds = this.selectedRecordIds
        let combinedIds = selectedIds.concat(newIds)
        combinedIds = [...new Set([...combinedIds])]
        setSessionStorageValue(this.recordIdParamValue, combinedIds)
      }
    }

    this.updateBulkActionUrl()

    this.showOrHideHeader()

    document.dispatchEvent(
      new CustomEvent("BulkActions:recordSelected", {
        detail: { recordId: this.getRecordId(e.target), isSelected: e.target.checked },
      }),
    )

    if (this.maxSelectionCountValue > 0) {
      const newSelectedIds = getSessionStorageValue(this.recordIdParamValue)
      if (newSelectedIds.length === this.maxSelectionCountValue) {
        this.disableOrEnableUncheckedCheckboxes("disable")
      } else if (
        oldSelectedIds.length === this.maxSelectionCountValue &&
        newSelectedIds.length < this.maxSelectionCountValue
      ) {
        this.disableOrEnableUncheckedCheckboxes("enable")
      }
    }
  }

  disableOrEnableUncheckedCheckboxes(disableOrEnable: string): void {
    const disable = disableOrEnable === "disable"
    const verb = disable ? "add" : "remove"
    this.allUncheckedToggles.forEach((toggle) => {
      toggle.disabled = disable
      toggle.classList[verb]("cursor-not-allowed", "pointer-events-none", "disabled")
    })

    const selectAllToggle = this.selectAllToggleInThisTable()
    const disableAndSelectAllToggleNotChecked = disable && !selectAllToggle.checked && !selectAllToggle.indeterminate
    if (selectAllToggle && (disableAndSelectAllToggleNotChecked || !disable)) {
      selectAllToggle.disabled = disable
      selectAllToggle.classList[verb]("cursor-not-allowed", "pointer-events-none", "disabled")
    }
  }

  allGroupToggles(): HTMLInputElement[] {
    return Array.from(this.element.querySelectorAll(".group-select-all")).filter((el) => !el.disabled)
  }

  showOrHideHeader(totalSelected = null): void {
    const bulkSelectionHeader = this.selectionHeaderOverride() || this.selectionHeaderInThisTable()
    const tableHeader = document.querySelector("#table-header")
    const scopeBar = document.querySelector("#table-scope-bar")
    if (bulkSelectionHeader == null) {
      return
    }

    if (isEmpty(totalSelected)) {
      if (this.isSingleSelectValue) {
        totalSelected = this.totalSelectionFromSession()
      } else {
        totalSelected = this.allCheckedTogglesInThisTable().length
      }
    }

    if (totalSelected > 0) {
      bulkSelectionHeader.classList.remove("hidden")
      tableHeader?.classList.add("hidden")
      scopeBar?.classList.add("hidden")
      this.updateRecordCount(bulkSelectionHeader, totalSelected)
      if (this.disableFilterWhenSelectedValue) this.disableOrEnableFilterButton("disable")
    } else {
      bulkSelectionHeader.classList.add("hidden")
      tableHeader?.classList.remove("hidden")
      scopeBar?.classList.remove("hidden")
      if (this.disableFilterWhenSelectedValue) this.disableOrEnableFilterButton("enable")
    }
  }

  disableOrEnableFilterButton(disableOrEnable: string): void {
    const filterButton = document.querySelector("#filters-button")
    if (filterButton) {
      if (disableOrEnable == "disable") {
        filterButton.classList.add("disabled")
        filterButton.parentElement.dataset.action = ""
      } else {
        filterButton.classList.remove("disabled")
        filterButton.parentElement.dataset.action = "click->filter-drawer#open"
      }
    }
  }

  updateRecordCount(header, length): void {
    const noun = length > 1 ? "items" : "item"
    header.getElementsByTagName("p")[0].innerHTML =
      length === this.maxSelectionCountValue
        ? `${length} ${noun} selected (maximum of ${this.maxSelectionCountValue} ${noun} selectable at a time)`
        : `${length} ${noun} selected`
  }

  updateRows(): void {
    this.togglesInThisTable().forEach((toggle: HTMLInputElement) => {
      if (toggle.checked) {
        this.setRowColor(toggle)
      } else {
        this.removeRowColor(toggle)
      }
    })
  }

  updateBulkActionUrl(): void {
    if (this.hasBulkActionBtnTarget) {
      const url = new URL(this.bulkActionBtnTarget.href)
      const queryParams = new URLSearchParams(url.search)

      queryParams.delete("record_id[]")

      this.recordIds.forEach((recordId) => {
        if (!queryParams.has("record_id[]", recordId)) {
          queryParams.append("record_id[]", recordId)
        }
      })

      const baseUrl = url.origin + url.pathname
      this.bulkActionBtnTarget.href = `${baseUrl}?${queryParams.toString()}`
    }
  }

  submitBulkAction(ev): void {
    ev.preventDefault()

    const input = document.createElement("input")
    input.type = "hidden"
    input.name = "ids"
    input.value = this.recordIds
    this.formTarget.appendChild(input)

    setSessionStorageValue(this.recordIdParamValue, [])
    setSessionStorageValue(this.recordIdsSetYetParamValue, null)

    this.formTarget.requestSubmit()
  }

  setRowColor(toggle): void {
    const row = toggle.closest(".table-row")
    const cell = toggle.closest(".table-cell")
    const bulkBackground = toggle.closest(".bulk-actions-checkbox-background")
    const docViewer = document.getElementById("document_viewer")
    if (bulkBackground != null) {
      bulkBackground.classList.remove("document-thumbnail-container-unselected")
      bulkBackground.classList.add("document-thumbnail-container-selected")
    } else if (docViewer != null) {
      toggle.closest("div").classList.add(this.rowColorValue)
      docViewer.classList.add(this.rowColorValue)
    } else if (this.highlightAvatarValue) {
      const initialsAvatar = row.querySelector(".initials-avatar")
      initialsAvatar?.classList?.remove("bg-gray-300")
      initialsAvatar?.classList?.add("bg-purple-500", "text-white")
    } else {
      cell.classList.add("border-l-4", "!border-l-purple-200", "pl-3")
    }
    if (row) {
      this.toggleGrayOutColumnsOnSelect(row, "add")
    }
  }

  removeRowColor(toggle): void {
    const row = toggle.closest(".table-row")
    const cell = toggle.closest(".table-cell")
    const bulkBackground = toggle.closest(".bulk-actions-checkbox-background")
    const docViewer = document.getElementById("document_viewer")
    if (bulkBackground != null) {
      bulkBackground.classList.add("document-thumbnail-container-unselected")
      bulkBackground.classList.remove("document-thumbnail-container-selected")
    } else if (docViewer != null) {
      toggle.closest("div").classList.remove(this.rowColorValue)
      docViewer.classList.remove(this.rowColorValue)
    } else if (this.highlightAvatarValue) {
      const initialsAvatar = row.querySelector(".initials-avatar")
      initialsAvatar?.classList?.remove("bg-purple-500", "text-white")
      initialsAvatar?.classList?.add("bg-gray-300")
    } else {
      cell.classList.remove("border-l-4", "!border-l-purple-200", "pl-3")
    }
    if (row) {
      this.toggleGrayOutColumnsOnSelect(row, "remove")
    }
  }

  toggleGrayOutColumnsOnSelect(row, action) {
    const grayOutColumnsOnSelect = row.dataset.grayOutColumnsOnSelect

    if (grayOutColumnsOnSelect) {
      const columns = JSON.parse(grayOutColumnsOnSelect)
      columns.forEach((columnName) => {
        const cell = row.querySelector(`[data-column-name="column-${columnName}"]`)
        if (cell) cell.classList[action]("bg-gray-50", "text-gray-500")
      })
    }
  }

  selectAllToggleInThisTable(): HTMLInputElement {
    return this.element.querySelector("#select_all.bulk_selection_select_all") as HTMLInputElement
  }

  selectAllToggleInThisGroup(group): HTMLInputElement {
    return this.element.querySelector(`#${group}`) as HTMLInputElement
  }

  togglesInThisTable(): HTMLInputElement[] {
    return Array.from(this.element.querySelectorAll(".select-record")).filter((el) => !el.disabled)
  }

  togglesInThisGroup(group): HTMLInputElement[] {
    return Array.from(this.element.querySelectorAll(`.${group}-record`)).filter((el) => !el.disabled)
  }

  get allToggles(): HTMLInputElement[] {
    return Array.from(document.querySelectorAll(".select-record"))
  }

  allCheckedTogglesInThisTable(): HTMLInputElement[] {
    return this.togglesInThisTable().filter((toggle) => toggle.checked)
  }

  allCheckedTogglesInThisGroup(group): HTMLInputElement[] {
    return this.togglesInThisGroup(group).filter((toggle) => toggle.checked)
  }

  get allCheckedToggles(): HTMLInputElement[] {
    return this.allToggles.filter((toggle) => toggle.checked)
  }

  get allUncheckedUndisabledToggles(): HTMLInputElement[] {
    return this.allToggles.filter((toggle) => !toggle.checked && !toggle.disabled)
  }

  get allUncheckedToggles(): HTMLInputElement[] {
    return this.allToggles.filter((toggle) => !toggle.checked)
  }

  selectionHeaderInThisTable(): HTMLInputElement {
    return this.element.querySelector(".bulk-selection-header")
  }

  selectionHeaderOverride(): HTMLElement | null {
    return document.getElementById(this.selectionHeaderIdValue)
  }

  get allRecordIds(): string[] {
    return this.allToggles.map((toggle) => {
      return this.getRecordId(toggle)
    })
  }

  get selectedRecordIds(): string[] {
    return this.allCheckedToggles.map((cell: HTMLElement) => {
      return this.getRecordId(cell)
    })
  }

  get recordIds(): string[] {
    if (!isEmpty(getSessionStorageValue(this.recordIdsSetYetParamValue))) {
      return getSessionStorageValue(this.recordIdParamValue).length > 0
        ? getSessionStorageValue(this.recordIdParamValue)
        : this.selectedRecordIds
    } else {
      return this.selectedRecordIds
    }
  }

  getRecordId(cell: HTMLElement): string {
    let tableRow = cell.closest(".table-row")

    if (tableRow != null) {
      cell = tableRow
    }
    if (cell) {
      return cell.id.split("_").at(-1)
    }
    return ""
  }
}
