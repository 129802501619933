import { Controller } from "@hotwired/stimulus"
import { pluralize } from "../utils/inflector"
import { isEmpty } from "../utils"

// Connects to data-controller="bulk-edit-errors-pill"
export default class extends Controller {
  static targets = ["errorsPill"]

  errorsPillTarget: HTMLDivElement
  hasErrorsPillTarget: boolean

  recalculateCountFunction: (event) => void

  connect() {
    this.recalculateCount()

    this.recalculateCountFunction = this.recalculateCount.bind(this)
    window.addEventListener("BulkEditErrorsPill:recalculateCount", this.recalculateCountFunction)
  }

  disconnect() {
    window.removeEventListener("BulkEditErrorsPill:recalculateCount", this.recalculateCountFunction)
  }

  recalculateCount(event?): void {
    let remainingErrors = 0
    const errorSidebar = document.querySelector("[data-bulk-edit-target='errorsSidebarExpanded']")

    if (this.hasErrorsPillTarget) {
      if (!isEmpty(event?.detail?.count)) {
        remainingErrors = Number(event.detail.count)
      } else {
        remainingErrors = errorSidebar.children.length - 1
      }

      let fullErrorText = this.errorsPillTarget.textContent.replace(/[^0-9] error?s/g, "")

      if (remainingErrors > 0) {
        this.errorsPillTarget.textContent = this.errorsPillTarget.textContent.replace(
          `${fullErrorText}`,
          `${remainingErrors.toString()} ${pluralize("error", remainingErrors)}`,
        )
      } else {
        this.errorsPillTarget.textContent = this.errorsPillTarget.textContent.replace(`${fullErrorText}`, "0 errors")
      }
    }
  }
}
