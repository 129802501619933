const generateUrl = (searchParams: string): string => {
  if (searchParams) {
    return `${window.location.pathname}?${searchParams}`
  } else {
    return window.location.pathname
  }
}

export const setSearchParams = (searchParams: URLSearchParams): void => {
  const newUrl = generateUrl(searchParams.toString())
  history.replaceState(history.state, "", newUrl)
}

export const buildUrl = (url: string, params: { [key: string]: any } = {}): string => {
  const [path, queryString] = url.split("?")
  const queryParams = new URLSearchParams(queryString)

  Object.entries(params).forEach(([key, val]) => {
    queryParams.append(key, val.toString())
  })

  return `${path}/?${queryParams.toString()}`
}
