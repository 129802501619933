import React, { useState } from "react"
import { useWorkflow } from "./workflowContext"
import { useReactFlow } from "reactflow"
import { data } from "autoprefixer"

interface IRulesAppliedBadgeProps {
  taskId: string
  rules: any[]
  editPath: string
}

export const RulesAppliedBadge = (props: IRulesAppliedBadgeProps) => {
  const { selectedTaskId, redirectToTask } = useWorkflow()
  const { getViewport } = useReactFlow()

  if (!props.rules || props.rules.length === 0) {
    return null
  }

  const totalRules = () => {
    return props.rules.length
  }

  const rulesText = () => {
    if (totalRules() > 1) {
      return "rules applied"
    }
    return "rule applied"
  }

  const handleLinkClick = (e) => {
    e.preventDefault()
    e.stopPropagation()

    redirectToTask(props.editPath, JSON.stringify(getViewport()), props.taskId, selectedTaskId, null, "rules")
  }

  const renderLink = () => {
    if (props.editPath) {
      return (
        <a
          id="rules-link"
          href="#"
          className="text-purple-600 underline hover:text-purple-700 hover:underline active:text-purple-800 active:underline"
          onClick={handleLinkClick}
        >
          {totalRules()}
        </a>
      )
    } else {
      return <span>{totalRules()}</span>
    }
  }

  return (
    <div id="<%= id %>" className="subtitle">
      {renderLink()}
      <span className="text-gray-500"> {rulesText()}</span>
    </div>
  )
}
