import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"
import _ from "lodash"
import { show, hide } from "../utils"
import { get } from "@rails/request.js"
import { createPopper, Options as PopperOptions } from "@popperjs/core"
import TableComponentController from "./table_component_controller"

type FilterConfigItem = { element_id: string; attribute: string }
type FilterValues = Record<string, any>

// Connects to data-controller="styled-select"
export default class extends Controller {
  static targets = [
    "optionTemplate",
    "optionCreateTemplate",
    "itemTemplate",
    "noResultsTemplate",
    "optGroupHeaderTemplate",
    "dropdownTemplate",
    "select",
    "container",
    "clearButton",
    "placeholder",
  ]

  static outlets = ["table-component"]

  static values = {
    filterConfig: String,
    optGroupsData: String,
    optionsData: String,
    optionsDataPath: String,
    pinLabel: String,
    pinnedOption: Array,
    config: String,
    disabled: Boolean,
    isMultiSelect: {
      default: false,
      type: Boolean,
    },
    addedItems: {
      default: [],
      type: Array,
    },
    selected: Array,
    // Adds the selected values to the list if not present in the given options
    forceAddSelected: {
      default: false,
      type: Boolean,
    },
    hideSelectedOnDropdownOpen: {
      default: false,
      type: Boolean,
    },
    enablePopperDropdown: {
      default: false,
      type: Boolean,
    },
    clearSelectionOnClick: {
      default: false,
      type: Boolean,
    },
  }

  tomSelect: any
  optGroupHeaderTemplateTarget: HTMLTemplateElement
  optionTemplateTarget: HTMLTemplateElement
  optionCreateTemplateTarget: HTMLTemplateElement
  noResultsTemplateTarget: HTMLTemplateElement
  itemTemplateTarget: HTMLTemplateElement
  dropdownTemplateTarget: HTMLTemplateElement
  selectTarget: HTMLSelectElement
  containerTarget: HTMLDivElement
  clearButtonTarget: HTMLSpanElement
  placeholderTarget: HTMLDivElement
  tsWrapper: Element
  filterCleanup: Function[] = [] // eslint-disable-line @typescript-eslint/no-unsafe-function-type
  filterConfigValue: string
  filterConfig: FilterConfigItem[]
  filterValues: FilterValues = {}
  optionsDataValue: string
  optionsData: Record<string, any>[]
  optGroupsData: Record<string, any>[]
  optGroupsDataValue: string
  optionsDataPathValue: string
  isMultiSelectValue: boolean
  hasOptGroupHeaderTemplateTarget: boolean
  hasOptionTemplateTarget: boolean
  hasOptionCreateTemplateTarget: boolean
  hasNoResultsTemplateTarget: boolean
  hasItemTemplateTarget: boolean
  hasDropdownTemplateTarget: boolean
  hasClearButtonTarget: boolean
  hasPlaceholderTarget: boolean
  disabledValue: boolean
  configValue: string
  hasPinLabelValue: boolean
  hasPinnedOptionValue: boolean
  pinLabelValue: string
  pinnedOptionValue: string[]
  addedItemsValue: string[]
  selectedValue: string[]
  forceAddSelectedValue: boolean
  lastValue: string
  hideSelectedOnDropdownOpenValue: boolean
  enablePopperDropdownValue: boolean
  clearSelectionOnClickValue: boolean
  tableComponentOutlet: TableComponentController

  // bind() creates a new function with the given context, we need to store the
  // reference of that function so we can then remove the listener
  onAddItemsHandler = this.onAddItems.bind(this)
  onRemoveItemsHandler = this.onRemoveItems.bind(this)
  onClearHandler = this.onClear.bind(this)
  onMoveOptionsToOptGroupHandler = this.onMoveOptionsToOptGroup.bind(this)
  onSetPlaceholderHandler = this.onSetPlaceholder.bind(this)
  onDestroyHandler = this.onDestroy.bind(this)
  onScrollHandler = this.onScroll.bind(this)
  onClickHandler = this.onClick.bind(this)

  popperInstance = null

  connect() {
    this.idempotentConnect()

    // Allows to programmatically add selected items from outside
    window.addEventListener("StyledSelect:addItems", this.onAddItemsHandler)
    // Allows to programmatically remove selected items from outside
    window.addEventListener("StyledSelect:removeItems", this.onRemoveItemsHandler)
    // Allows to programmatically clear the value from outside
    window.addEventListener("StyledSelect:clear", this.onClearHandler)
    // Allows to programmatically move options to an optgroup
    window.addEventListener("StyledSelect:moveOptionsToOptGroup", this.onMoveOptionsToOptGroupHandler)
    // Allows to programmatically change the placeholder text
    window.addEventListener("StyledSelect:setPlaceholder", this.onSetPlaceholderHandler)
    // Allows to programmatically destroy the tom select instance
    window.addEventListener("StyledSelect:destroy", this.onDestroyHandler)

    this.containerTarget.addEventListener("click", this.onClickHandler, true)
  }

  idempotentConnect() {
    this.initTomSelect()
    this.initClearButton()
    if (this.disabledValue) {
      this.disableTomSelect()
    }
    this.initFilters()
    this.parseOptionsData()
    this.parseOptGroupsData()
  }

  reconnect() {
    const currentHtml = this.selectTarget.innerHTML
    this.tomSelect.destroy()
    // tomSelect caches the original select element on setup and restores it on destroy. This
    // ensures that on reconnect, the current selected option gets reapplied.
    this.selectTarget.innerHTML = currentHtml
    this.idempotentConnect()
  }

  disableInput(clearValue: boolean = true) {
    if (clearValue) {
      this.tomSelect.clear()
    }
    this.disabledValue = true
  }

  enableInput() {
    this.disabledValue = false
  }

  disconnect() {
    this.disableFilters()

    window.removeEventListener("StyledSelect:addItems", this.onAddItemsHandler)
    window.removeEventListener("StyledSelect:removeItems", this.onRemoveItemsHandler)
    window.removeEventListener("StyledSelect:clear", this.onClearHandler)
    window.removeEventListener("StyledSelect:moveOptionsToOptGroup", this.onMoveOptionsToOptGroupHandler)
    window.removeEventListener("StyledSelect:setPlaceholder", this.onSetPlaceholderHandler)
    window.removeEventListener("StyledSelect:destroy", this.onDestroyHandler)
    this.containerTarget.removeEventListener("click", this.onClickHandler, true)

    this.tomSelect.destroy()
  }

  // Outlet callbacks
  tableComponentOutletConnected(outlet): void {
    outlet.tableComponentTarget.addEventListener("scroll", this.onScrollHandler) // Hide popper on scroll
  }

  tableComponentOutletDisconnected(outlet): void {
    outlet.tableComponentTarget.removeEventListener("scroll", this.onScrollHandler)
  }

  disabledValueChanged() {
    if (this.tomSelect) {
      // Do nothing if tomSelect not yet initialized (i.e., on initial load when disable value is set)
      if (this.disabledValue) {
        this.disableTomSelect()
      } else {
        this.tomSelect.enable()
        this.setDefaultStyles()
      }
    }
  }

  filter = (attribute: string) => (e: Event) => {
    this.filterValues[attribute] = (e.target as HTMLInputElement).value
    this.tomSelect.clear()
    this.filterOptionsData()
  }

  disableFilters() {
    while (this.filterCleanup.length > 0) {
      const cleanupFunc = this.filterCleanup.shift()
      cleanupFunc()
    }
  }

  initFilters() {
    this.filterConfig = JSON.parse(this.filterConfigValue)
    this.filterConfig.forEach(({ attribute, element_id }) => {
      const element = document.getElementById(element_id)
      const filterList = this.filter(attribute)

      element.addEventListener("change", filterList)
      element.addEventListener("input", filterList)
      this.filterCleanup.push(() => {
        element.removeEventListener("change", filterList)
        element.removeEventListener("input", filterList)
      })
    })
  }

  parseOptionsData() {
    if (!this.hasOptionTemplateTarget) return
    this.optionsData = JSON.parse(this.optionsDataValue)
  }

  parseOptGroupsData() {
    if (!this.hasOptGroupHeaderTemplateTarget) return
    this.optGroupsData = JSON.parse(this.optGroupsDataValue)
  }

  filterOptionsData = () => {
    const isMatching = (o: Record<string, any>) =>
      Object.keys(this.filterValues).every((key: string) => {
        const setValue = this.filterValues[key]
        return !setValue ? true : o[key] === setValue
      })

    this.optionsData.forEach((o: Record<string, any>) => {
      isMatching(o) ? this.tomSelect.addOption({ text: o.label, ...o }) : this.tomSelect.removeOption(o.value)
    })
  }

  initTomSelect() {
    const config = JSON.parse(this.configValue)
    if (this.hasPinnedOption()) {
      config.persist = false
    }
    if (config.closeAfterSelect === undefined) {
      config.closeAfterSelect = true
    }
    if (config.allowItemRemove === undefined) {
      config.allowItemRemove = true
    }

    const plugins = { ...config.plugins }

    if (this.isMultiSelectValue && config.allowItemRemove) {
      plugins["remove_button"] = {
        remove_text: "Remove item",
      }
    }

    if (config.create) {
      config.create = (input: string): { value: string; text: string } => {
        this.addedItemsValue = [...this.addedItemsValue, input]
        return { value: input, text: input }
      }
    }

    if (this.isMultiSelectValue && this.selectedValue.length > 0) {
      config.items = this.selectedValue
    }

    this.tomSelect = new TomSelect(this.selectTarget, {
      ...config,
      plugins,
      render: {
        item: (data, escape) =>
          this.hasItemTemplateTarget ? this.customItemTemplate(data, escape) : this.defaultItemTemplate(data, escape),
        option: (data, escape) =>
          this.hasOptionTemplateTarget
            ? this.customOptionTemplate(data, escape)
            : this.defaultOptionTemplate(data, escape),
        dropdown: () =>
          this.hasDropdownTemplateTarget ? this.dropdownTemplateTarget.innerHTML : this.defaultDropdownTemplate(),
        option_create: (data, escape) =>
          this.hasOptionCreateTemplateTarget
            ? this.optionCreateTemplateTarget.innerHTML
            : this.defaultOptionCreateTemplate(data, escape),
        no_results: (data, escape) =>
          this.hasNoResultsTemplateTarget
            ? this.customNoResultsTemplate(data, escape)
            : this.defaultNoResultsTemplate(data, escape),
        optgroup_header: (data, escape) =>
          this.hasOptGroupHeaderTemplateTarget
            ? this.customOptGroupHeaderTemplate(data, escape)
            : this.defaultOptGroupHeaderTemplate(data, escape),
      },
      onInitialize: this.onInitialize.bind(this),
      onDelete: this.onDelete.bind(this),
      onFocus: this.onFocus.bind(this),
      // if optionsDataPathValue is set, we'll call the load function to populate options
      ...(this.optionsDataPathValue && { load: this.load.bind(this) }),
    })

    if (this.forceAddSelectedValue) {
      const selectedValue = [this.selectedValue].flat()
      selectedValue.forEach((option) => {
        this.tomSelect.addOption({ text: option, value: option }, true)
        this.tomSelect.addItem(option, true)
      })
    }

    this.tomSelect.on("item_add", this.onItemSelection.bind(this))
    this.tomSelect.on("item_remove", this.onItemRemove.bind(this))
    this.tomSelect.on("change", this.onValueChange.bind(this))
    this.tomSelect.on("dropdown_open", this.onDropdownOpen.bind(this))
    this.tomSelect.on("dropdown_close", this.onDropdownClose.bind(this))
    if (config.create) {
      this.tomSelect.on("type", this.onType.bind(this))
    }

    this.populatePinnedOptionValue(config?.items || [])
  }

  disableTomSelect() {
    this.tomSelect.disable()
    this.tsWrapper.setAttribute(
      "style",
      "border-color: rgb(203 213 224/var(--tw-border-opacity)); opacity: 1; background-color: rgb(247 250 252/var(--tw-bg-opacity));",
    )
    const tsControl = this.containerTarget.getElementsByClassName("ts-control")[0]
    tsControl.setAttribute("style", "cursor: not-allowed !important; opacity: 1; border-radius: 0.375rem;")
  }

  onDelete(value: string | string[]) {
    // If this is the last item being deleted, add placeholder
    if (this.tomSelect.getValue() && this.tomSelect.getValue().length === 1) {
      const config = JSON.parse(this.configValue)
      if (config.placeholderValue) {
        this.addPlaceholder(config.placeholderValue)
      }
    }
  }

  onFocus(value: string | string[]) {
    this.tomSelect.open()
  }

  async load(query, callback) {
    const response = await get(`${this.optionsDataPathValue}?q=${query}`)
    if (response.ok) {
      const body = JSON.parse(await response.text)
      callback(body)
    } else {
      callback()
    }
  }

  initClearButton() {
    if (this.hasClearButtonTarget) {
      const value = this.tomSelect.getValue()
      const hasValue = value.length > 0
      if (!hasValue) {
        hide(this.clearButtonTarget)
      } else {
        show(this.clearButtonTarget)
      }
    }
  }

  onInitialize() {
    this.tsWrapper = this.containerTarget.getElementsByClassName("ts-wrapper")[0]

    this.selectTarget.classList.remove("hidden")
    this.tsWrapper.classList.remove("hidden") // input classes are copied to wrapper
    this.setDefaultStyles()
    if (this.hasPlaceholderTarget) {
      this.placeholderTarget.remove()
    }

    window.dispatchEvent(
      new CustomEvent("StyledSelect:initialize", {
        detail: { origin: this.selectTarget },
      }),
    )
  }

  setDefaultStyles() {
    this.tsWrapper.setAttribute("style", "background-color: white;")
    const tsControl = this.containerTarget.getElementsByClassName("ts-control")[0]
    tsControl.setAttribute("style", "border-radius: 6px")
  }

  onValueChange(value: string | string[]): void {
    if (this.hideSelectedOnDropdownOpenValue && value !== "") {
      this.lastValue = undefined
    }
    const isUserAdded = this.addedItemsValue.includes(value as string)
    window.dispatchEvent(
      new CustomEvent("StyledSelect:valueChanged", {
        detail: {
          value,
          origin: this.selectTarget,
          isUserAdded,
        },
      }),
    )

    this.hideShowClearButton(value)
  }

  // move "Add New..." text to bottom of the dropdown
  onType(): void {
    const createEL = document.querySelector(".ts-dropdown-content .create")
    if (createEL) {
      createEL.parentElement.append(createEL)
    }
  }

  clearSelect() {
    this.tomSelect.clear()
    const config = JSON.parse(this.configValue)
    if (config.placeholderValue) {
      this.addPlaceholder(config.placeholderValue)
    }
  }

  toggle(e: Event) {
    e.stopPropagation()
    if (this.tomSelect.isOpen) {
      this.tomSelect.close()
    } else {
      this.tomSelect.open()
    }
  }

  addPlaceholder(text: string): void {
    this.tomSelect.settings.placeholder = text
    this.tomSelect.inputState()
  }

  hideShowClearButton(value: string | string[]) {
    if (!this.hasClearButtonTarget) {
      return
    }

    const hasValue = value.length > 0
    if (hasValue) {
      this.tomSelect.wrapper.classList.add("has-clear-icon")
      show(this.clearButtonTarget)
    } else {
      this.tomSelect.wrapper.classList.remove("has-clear-icon")
      hide(this.clearButtonTarget)
    }
  }

  defaultItemTemplate(data, escape): string {
    const templateFunc = this.isMultiSelectValue
      ? this.defaultMultiSelectItemTemplate
      : this.defaultSingleSelectItemTemplate

    return templateFunc(data, escape)
  }

  defaultMultiSelectItemTemplate(data, escape): string {
    return `<div id="selected-item_${data.value}" class="!bg-purple-100 rounded-md">${escape(data.text)}</div>`
  }

  defaultSingleSelectItemTemplate(data, escape): string {
    return `<div>${escape(data.text)}</div>`
  }

  defaultOptionTemplate(data, escape): string {
    return `<div class="hover:!bg-gray-100 focus:!bg-gray-100">${escape(data.text)}</div>`
  }

  defaultDropdownTemplate(): string {
    if (this.hasPinnedOption()) {
      return this.defaultDropdownWithPinnedOptionTemplate()
    }
    return `<div class="z-dropdown absolute w-inherit"></div>`
  }

  defaultOptGroupHeaderTemplate(data, escape): string {
    return `<div class="optgroup-header subtitle">${escape(data.label)}</div>`
  }

  defaultOptionCreateTemplate(data, escape): string {
    return `
      <div class="create !text-purple-500">
        <i class="fa fa-plus"></i>
        Add new ${escape(data.input)}
      </div>
    `
  }

  defaultNoResultsTemplate(data, escape): string {
    return `<div class="no-results">No results found</div>`
  }

  defaultDropdownWithPinnedOptionTemplate() {
    const pinnedOption = this.sanitizePinnedOption()
    let label = ""
    if (this.hasPinLabelValue) {
      label = `
        <div class="pb-1 px-2 cursor-default">
          <span class="text-gray-800 text-sm">${this.pinLabelValue}</span>
        </div>
      `
    }
    return `
      <div class="z-dropdown absolute">
        ${label}
        <div id="${this.containerTarget?.id}-opt-pinned" class="hover:!bg-gray-100 focus:!bg-gray-100 py-1 px-2" data-value="${pinnedOption[1]}" data-action="click->styled-select#selectPinnedOption">
            ${pinnedOption[0]}
        </div>
        <div class="pb-1 px-2 cursor-default">
          <div class="my-1 border-b border-base"></div>
        </div>
      </div>
    `
  }

  customItemTemplate(data, escape): string | null {
    return this.parseTemplateData({
      templateTarget: this.itemTemplateTarget,
      templateJsonData: this.optionsDataValue,
      data,
      escape,
    })
  }

  customOptionTemplate(data, escape): string | null {
    return this.parseTemplateData({
      templateTarget: this.optionTemplateTarget,
      templateJsonData: this.optionsDataValue,
      data,
      escape,
    })
  }

  customOptGroupHeaderTemplate(data, escape): string | null {
    return this.parseTemplateData({
      templateTarget: this.optGroupHeaderTemplateTarget,
      templateJsonData: this.optGroupsDataValue,
      data,
      escape,
    })
  }

  customNoResultsTemplate(data, escape): string | null {
    return this.parseTemplateData({
      templateTarget: this.noResultsTemplateTarget,
      templateJsonData: this.optionsDataValue,
      data,
      escape,
    })
  }

  parseTemplateData({ templateTarget, templateJsonData, data, escape }: ParseTemplateDataOptions) {
    const templateString = templateTarget.innerHTML
    const templateFunction = _.template(templateString, this.template_options)

    const parsedTemplateData = templateJsonData ? JSON.parse(templateJsonData) : {}
    const dataAccessor = Array.isArray(parsedTemplateData) ? data["$order"] - 1 : data.value

    const parsedData = parsedTemplateData[dataAccessor] || {}
    const singleRowOptionData = { ...parsedData, ...data }

    const sanitizedData = this.sanitizeTemplateData(singleRowOptionData, escape)

    return templateFunction(sanitizedData)
  }

  sanitizeTemplateData(data: object, escape: EscapeDataFunc) {
    const sanitizedData = {}
    Object.keys(data).forEach((key) => {
      sanitizedData[key] = escape(data[key])
    })

    return sanitizedData
  }

  hasPinnedOption() {
    return this.hasPinnedOptionValue && Array.isArray(this.pinnedOptionValue) && this.pinnedOptionValue.length === 2
  }

  sanitizePinnedOption() {
    if (this.hasPinnedOption()) {
      return this.pinnedOptionValue.map((v) => _.escape(v))
    }
    return []
  }

  populatePinnedOptionValue(defaultSelectedValues) {
    if (this.hasPinnedOption()) {
      const [, pinnedValue] = this.sanitizePinnedOption()
      if (defaultSelectedValues.find((o) => o === pinnedValue)) {
        this.selectPinnedOption()
      }
    }
  }

  selectPinnedOption() {
    if (this.hasPinnedOption()) {
      const pinnedOption = this.sanitizePinnedOption()
      this.tomSelect.addOption({ text: pinnedOption[0], value: pinnedOption[1] }, true)
      this.tomSelect.addItem(pinnedOption[1], true)
      this.tomSelect.refreshItems()
    }
  }

  onItemSelection(value, itemDomRef) {
    if (this.optionsDataValue) {
      const parsedOptionsData = JSON.parse(this.optionsDataValue)
      const item = parsedOptionsData.find((o) => o.id === value)

      item &&
        window.dispatchEvent(
          new CustomEvent("StyledSelect:itemAdded", { detail: { item, value, itemDomRef, origin: this.selectTarget } }),
        )
    }

    this.tomSelect.setTextboxValue("")

    if (this.tomSelect.settings.closeAfterSelect) {
      this.tomSelect.close()
    }
  }

  onItemRemove(value, itemDomRef) {
    if (this.optionsDataValue) {
      const parsedOptionsData = JSON.parse(this.optionsDataValue)
      const item = parsedOptionsData.find((o) => o.id === value)
      item &&
        window.dispatchEvent(
          new CustomEvent("StyledSelect:itemRemoved", {
            detail: { item, value, itemDomRef, origin: this.selectTarget },
          }),
        )
    }
  }

  onAddItems(event: CustomEvent) {
    const { target, items, silent } = event.detail

    if (!Array.isArray(items)) {
      throw new Error("items must be an array")
    }

    if (this.selectTarget === target) {
      items.forEach((ii) => {
        this.tomSelect.addItem(ii, silent || false)
      })
    }
  }

  onRemoveItems(event: CustomEvent) {
    const { target, items, silent } = event.detail

    if (!Array.isArray(items)) {
      throw new Error("items must be an array")
    }

    if (this.selectTarget === target) {
      items.forEach((i) => {
        this.tomSelect.removeItem(i, silent || false)
      })
    }
  }

  onClear(event: CustomEvent) {
    const { target, silent } = event.detail

    if (this.selectTarget === target) {
      this.tomSelect.clear(silent || false)
      hide(this.clearButtonTarget)
    }
  }

  onMoveOptionsToOptGroup(event: CustomEvent) {
    const { target, options, optgroup } = event.detail

    if (this.selectTarget === target && Array.isArray(options)) {
      options.forEach((o) => {
        this.tomSelect.updateOption(o.id, {
          ...o,
          text: o.hasOwnProperty("text") ? o.text : o.label || o.name,
          value: o.hasOwnProperty("value") ? o.value : o.id,
          optgroup: optgroup,
        })
      })
    }
  }

  onSetPlaceholder(event: CustomEvent): void {
    const { target, placeholder } = event.detail

    if (this.selectTarget === target) {
      this.tomSelect.settings.placeholder = placeholder || "Select"
      this.tomSelect.inputState()
    }
  }

  onDestroy(event: CustomEvent) {
    const { target } = event.detail

    if (this.selectTarget === target) {
      this.tomSelect.destroy()
    }
  }

  onDropdownOpen() {
    if (this.hideSelectedOnDropdownOpenValue) {
      this.lastValue = this.tomSelect.getValue()
      this.tomSelect.clear()
    }

    // Moves the create new option to the end of the dropdown
    const createEL = document.getElementById("create-new-option")
    if (createEL) {
      createEL.parentElement.append(createEL)
    }

    // initialize popper instance
    if (this.enablePopperDropdownValue) {
      const dropdownElement = this.tomSelect.dropdown
      const referenceElement = this.tomSelect.control.parentElement
      dropdownElement.style.width = `${this.containerTarget.offsetWidth}px`

      this.popperInstance = createPopper(referenceElement, dropdownElement, this.popperConfig())
    }

    window.dispatchEvent(
      new CustomEvent("StyledSelect:dropdownOpened", {
        detail: { origin: this.selectTarget, container: this.containerTarget },
      }),
    )
  }

  onDropdownClose() {
    if (this.popperInstance) {
      this.popperInstance.destroy()
      this.popperInstance = null
    }

    if (this.hideSelectedOnDropdownOpenValue && this.lastValue) {
      this.tomSelect.setValue(this.lastValue, true)
      this.lastValue = undefined
    }
    window.dispatchEvent(
      new CustomEvent("StyledSelect:dropdownClosed", {
        detail: { origin: this.selectTarget, container: this.containerTarget },
      }),
    )
  }

  onScroll() {
    this.tomSelect.blur()
  }

  onClick(event: Event) {
    const shouldClearSelectedValue = this.clearSelectionOnClickValue && this.tomSelect.getValue()
    if (shouldClearSelectedValue) {
      event.stopPropagation()
      this.clearSelect()
    }
  }

  selectByName(name: string) {
    const options = this.tomSelect.options

    for (const key in options) {
      const option = options[key]
      if (option.text.toLowerCase() === name.toLowerCase()) {
        return this.tomSelect.setValue(option.value)
      }
    }
  }

  popperConfig(): PopperOptions {
    return {
      placement: "bottom-start",
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 5],
          },
        },
        {
          name: "preventOverflow",
        },
      ],
      strategy: "fixed",
    }
  }

  /**
   * {{}} for eval
   * {{= }} for interpolation
   */
  get template_options() {
    return {
      evaluate: /\{\{([\s\S]+?)\}\}/g,
      interpolate: /\{\{=([\s\S]+?)\}\}/g,
      escape: /\{\{-([\s\S]+?)\}\}/g,
    }
  }
}

type ParseTemplateDataOptions = {
  templateTarget: HTMLTemplateElement
  templateJsonData?: string
  data: any
  escape: EscapeDataFunc
}

type EscapeDataFunc = (str: string) => string
