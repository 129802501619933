import { Controller } from "@hotwired/stimulus"
import { hide, show } from "../utils"

// Connects to data-controller="purchase-requisition-line-form"
export default class extends Controller {
  static targets = ["sectionHeader", "quantityField", "typeField"]
  sectionHeaderTarget: HTMLElement
  quantityFieldTarget: HTMLInputElement
  typeFieldTarget: HTMLSelectElement

  connect() {
    if (this.typeFieldTarget.value === "RequisitionAmountLine") {
      hide(this.quantityFieldTarget.closest(".input-field-wrapper"))
    }
  }

  handleTypeChange(e) {
    const newType = e.target.value
    if (newType === "RequisitionAmountLine") {
      hide(this.quantityFieldTarget.closest(".input-field-wrapper"))
    } else {
      show(this.quantityFieldTarget.closest(".input-field-wrapper"))
    }
  }

  handleItemChange(e) {
    const newValue = e.target.value
    if (newValue.length) {
      this.sectionHeaderTarget.innerHTML = newValue
    } else {
      this.sectionHeaderTarget.innerHTML = "Add Line"
    }
  }
}
