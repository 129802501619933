import { Controller } from "@hotwired/stimulus"
import { hideShallowModeModal, showShallowModeModal } from "../utils"
import { pluralize } from "../utils/inflector"

// Connects to data-controller="rule-group"
export default class extends Controller {
  static targets = ["modalRuleCount", "outerContainer"]

  modalRuleCountTarget: HTMLSpanElement
  outerContainerTarget: HTMLDivElement

  connect() {}

  showRemoveGroupModal() {
    this.updateRuleCount()
    showShallowModeModal(this.outerContainerTarget, this.application)
  }

  updateRuleCount() {
    const rules = Array.from(this.outerContainerTarget.querySelectorAll(".rule-field")).filter(
      (el) => !el.closest("template"),
    )
    const ruleCount = rules.length
    const ruleCountString = `${ruleCount.toString()} ${pluralize("rule", ruleCount)}`
    this.modalRuleCountTarget.textContent = ruleCountString
  }

  hideRemoveGroupModal(e): void {
    e.preventDefault()
    e.stopPropagation()

    hideShallowModeModal(this.outerContainerTarget, this.application)
  }
}
