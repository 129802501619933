import { Controller } from "@hotwired/stimulus"
import { pluralizeWithDelimiter } from "../../utils/inflector"
import { hide, show } from "../../utils/index"

let lastScrollY = -1
let submitting = false
let visibleElements: Array<HTMLElement>

// Connects to data-controller="settings--map-departments"
export default class extends Controller {
  static targets = [
    "bottomScrollBuffer",
    "bulkEditingCount",
    "hiddenFieldReset",
    "hiddenFieldLastChangedDepartment",
    "mirrorButton",
    "resetButton",
    "scrollBottomBuffer",
    "searchInput",
    "setDepartmentCount",
    "unmappedOnlySwitch",
  ]

  static outlets = ["bulk-actions"]

  bottomScrollBufferTarget: HTMLElement
  bulkEditingCountTarget: HTMLElement
  hiddenFieldResetTarget: HTMLInputElement
  hiddenFieldLastChangedDepartmentTarget: HTMLInputElement
  mirrorButtonTarget: HTMLElement
  resetButtonTarget: HTMLElement
  searchInputTarget: HTMLInputElement
  setDepartmentCountTarget: HTMLElement
  unmappedOnlySwitchTarget: HTMLInputElement

  onDropdownClosedFunction: (event) => void
  onDropdownOpenFunction: (event) => void

  connect() {
    this.addEventListeners()

    this.update()
    submitting = false
  }

  disconnect() {
    this.removeEventListeners()
  }

  // ---------------- Stimulus Actions ----------------

  submitMirror(event): void {
    if (submitting) {
      return
    }
    const selectedCheckboxes = this.bulkActionsOutlet.allCheckedTogglesInThisTable()

    selectedCheckboxes.forEach((checkbox) => {
      const rowDiv = checkbox.closest(".table-row")

      const toId = rowDiv.querySelector(".select-input").dataset.tropicDepartment
      const name = rowDiv.id.split("department_selection_").at(-1)
      const hiddenField = rowDiv.querySelector(".mirror-map-setting")
      hiddenField.value = toId || name
    })
    this.submit(event)
  }

  submitReset(event): void {
    if (submitting) {
      return
    }
    this.hiddenFieldResetTarget.value = "1"
    this.submit(event)
  }

  submitUpdate(event) {
    const selectedText = this.selectedText(event.target.closest(".map-departments-row"))
    if (!selectedText || submitting) {
      return
    }

    this.hiddenFieldLastChangedDepartmentTarget.value = event.target.id.replace("select-", "")
    this.submit(event)
  }

  uncheckAllToggles() {
    this.bulkActionsOutlet.uncheckAllToggles()
    this.update()
  }

  update() {
    this.hideBottomScrollBuffer()

    this.updateHistory()
    this.filterBySearch()
    this.filterByUnmapped()
    this.renderBulkEditHeader()
    this.validate()
    this.updateBulkEditRows()
    this.scrollTarget()
  }

  //---------------- Main UI update sequence ----------------

  // Maintain URL params for bookmarking and back button
  updateHistory() {
    let params = new URLSearchParams(window.location.search)

    const search = this.searchInputTarget.value
    const unmapped = this.unmappedOnlySwitchTarget.checked

    if (search && search.length > 0) {
      params.set("search", search)
    } else {
      params.delete("search")
    }
    if (unmapped) {
      params.set("unmapped", "1")
    } else {
      params.delete("unmapped")
    }

    const oldPath = window.location.pathname
    const newPath = oldPath + "?" + params.toString()
    if (oldPath !== newPath) {
      history.replaceState(null, "", newPath)
    }
  }

  // Next, hide the rows that don't match the search
  filterBySearch() {
    const search = this.searchInputTarget.value.toLowerCase()
    visibleElements = []

    this.forEachRow((rowDiv: HTMLElement) => {
      const name = rowDiv.id.replace("department_selection_", "").toLowerCase()
      if (name.includes(search)) {
        this.showRowAndEnableToggle(rowDiv)
        visibleElements.unshift(rowDiv)
      } else {
        this.hideRowAndDisableToggle(rowDiv)
      }
    })
  }

  // Finally, hide the rows that are already mapped if the switch is on
  filterByUnmapped() {
    const unmapped = !!this.unmappedOnlySwitchTarget.checked
    const valueSet = new Set()

    this.forEachRow((rowDiv: HTMLElement) => {
      const selectedText = this.selectedText(rowDiv)

      // Cannot rely on position (selected index) here due to TomSelect
      if (selectedText) {
        valueSet.add(selectedText)
        if (unmapped) {
          this.hideRowAndDisableToggle(rowDiv)
        }
      }
    })

    this.setDepartmentCountTarget.innerHTML = pluralizeWithDelimiter("Department", valueSet.size)
  }

  // Show bulk edit header if any checkboxes are checked
  renderBulkEditHeader() {
    const bulkSelectionCountDiv: HTMLDivElement = this.element.querySelector("div#mapping-table-header-bulk-select")
    const mappingTableHeader: HTMLDivElement = this.element.querySelector("div#mapping-table-header-search")

    let bulkEditSelectedCount = 0

    this.forEachRow((rowDiv: HTMLElement) => {
      if (rowDiv.querySelector(".select-record").checked) {
        bulkEditSelectedCount += 1
      }
    })

    this.disableDepartmentSelects(bulkEditSelectedCount)

    this.bulkEditingCountTarget.innerHTML = pluralizeWithDelimiter("Selection", bulkEditSelectedCount)

    if (bulkEditSelectedCount > 0) {
      show(bulkSelectionCountDiv)
      hide(mappingTableHeader)
    } else {
      hide(bulkSelectionCountDiv)
      show(mappingTableHeader)
    }
  }

  // Check for various validation states in the UI
  validate() {
    let disableSubmit = false
    const archivedNames = this.archivedNames()

    this.forEachRow((rowDiv: HTMLElement) => {
      const selector = this.selector(rowDiv)
      const selectedText = this.selectedText(rowDiv)
      if (!selectedText) {
        disableSubmit = true
      }
      const departmentId = this.extractDepartmentId(selector)
      const warningBox = document.querySelector(`div#select-warning-${departmentId}`)
      if (archivedNames.includes(selectedText)) {
        show(warningBox)
      } else {
        hide(warningBox)
      }
    })
    const submitButton: HTMLAnchorElement = document.querySelector(".full-screen-footer-right-button")
    if (disableSubmit) {
      submitButton.classList.add("disabled-link-button", "bg-gray-400")
    } else {
      submitButton.classList.remove("disabled-link-button", "bg-gray-400")
    }
  }

  updateBulkEditRows() {
    this.bulkActionsOutlet.updateRows()
  }

  scrollTarget() {
    if (lastScrollY < 0) {
      return
    }

    // Go to the back of the line to let Turbo finish messing with the DOM
    setTimeout(function () {
      window.scrollTo(0, lastScrollY)
      lastScrollY = -1
    }, 0)
  }

  //---------------- Utility functions ----------------

  // This is a small buffer to prevent the option dropdown from being hidden behind the footer
  hideBottomScrollBuffer() {
    hide(this.bottomScrollBufferTarget)
  }

  showBottomScrollBuffer() {
    show(this.bottomScrollBufferTarget)
  }

  // Do something for all the rows in the table, hidden or not
  forEachRow(iterationFunction) {
    document.querySelectorAll(".map-departments-row").forEach((el) => iterationFunction(el))
  }

  hideRowAndDisableToggle(rowDiv) {
    hide(rowDiv)
    const rowBulkToggle = rowDiv.querySelector(".select-record")
    rowBulkToggle.checked = false
    rowBulkToggle.disabled = true
    visibleElements = visibleElements.filter((item) => item !== rowDiv)
  }

  showRowAndEnableToggle(rowDiv) {
    show(rowDiv)
    const rowBulkToggle = rowDiv.querySelector(".select-record")
    rowBulkToggle.disabled = false
  }

  // Get the selected text string from the selector
  selectedText(rowDiv: HTMLElement): string {
    const selector = this.selector(rowDiv)
    const selectedOption = selector.options[selector.selectedIndex]
    if (selectedOption) {
      const text = selectedOption.text.toLowerCase()
      if (text !== "select" && text !== "Search Departments") {
        return text
      }
    }
    return ""
  }

  disableDepartmentSelects(selectedCount) {
    this.forEachRow((rowDiv: HTMLElement) => {
      const departmentSelect = rowDiv.querySelector(".department-select")

      if (rowDiv.querySelector(".select-record").checked || selectedCount === 0) {
        departmentSelect.dataset.styledSelectDisabledValue = false
      } else {
        departmentSelect.dataset.styledSelectDisabledValue = true
      }
    })
  }

  archivedNames() {
    let archivedNames = []
    this.element.querySelectorAll("div.archived-names").forEach(function (archivedNameElement) {
      archivedNames.push(archivedNameElement.dataset.archivedName)
    })
    return archivedNames
  }

  extractDepartmentId(selection) {
    return selection.name.replace("pending_department_map[", "").replace("]", "")
  }

  // Get the selector element for the row
  selector(rowDiv: HTMLElement): HTMLSelectElement {
    return rowDiv.querySelector("select.select-input")
  }

  // Submit the form safely
  submit(event): void {
    if (event) {
      event.preventDefault()
    }
    this.forEachRow((rowDiv: HTMLElement) => {
      const departmentSelect = rowDiv.querySelector(".department-select")
      departmentSelect.dataset.styledSelectDisabledValue = true
    })
    this.resetButtonTarget.disabled = true
    this.mirrorButtonTarget.disabled = true

    submitting = true
    lastScrollY = window.scrollY

    this.element.closest("form").requestSubmit()
  }

  //---------------- Event Listeners ----------------

  onDropdownOpen(event): void {
    const { _origin, container } = event.detail
    const rowDiv = container.closest(".map-departments-row")
    const fromLast = visibleElements.indexOf(rowDiv)
    if (fromLast < 3) {
      this.showBottomScrollBuffer()
      rowDiv.querySelector(".map-departments-row-bottom").scrollIntoView()
    }
  }

  onDropdownClosed(event): void {
    this.hideBottomScrollBuffer()
  }

  addEventListeners() {
    this.onDropdownOpenFunction = this.onDropdownOpen.bind(this)
    window.addEventListener("StyledSelect:dropdownOpened", this.onDropdownOpenFunction)
    this.onDropdownClosedFunction = this.onDropdownClosed.bind(this)
    window.addEventListener("StyledSelect:dropdownClosed", this.onDropdownClosedFunction)
  }

  removeEventListeners() {
    window.removeEventListener("StyledSelect:dropdownOpened", this.onDropdownOpenFunction)
    window.removeEventListener("StyledSelect:dropdownClosed", this.onDropdownClosedFunction)
  }
}
