import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="unisearch--shortcut"
export default class extends Controller {
  static targets = ["keylabel"]

  connect(): void {
    let isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0
    if (isMac) {
      this.keylabelTarget.innerHTML = "&#8984;K"
    } else {
      this.keylabelTarget.innerHTML = "Ctrl+K"
    }
  }

  showModal(event: Event): void {
    // Hack to ignore non KeyboardEvent Events that come in from the global shortcut
    // This is caused by chrome autocomplete selections
    // Fixed by: https://github.com/hotwired/stimulus/pull/713
    if (
      !(event instanceof KeyboardEvent) &&
      event instanceof Event &&
      (event.type === "keydown" || event.type === "keyup")
    ) {
      return
    }

    document.getElementById("nav_unisearch")?.click()
    // This is how we WANTED to do it, but if fails to trigger the modal on a fresh page
    // get("/orgs/tropic/unisearch/new", { responseKind: "turbo-stream" })
  }
}
